footer.content-info{
    background-color:$marron;
}

.imgfooter{
    margin-top: 10px;
    margin-bottom: 10px;
}

#bottom-site{
    background-color:$marron;
    text-align:center;
    ul{
        margin:0;
        padding:0;
        li a{
            display:block;
            padding:15px 15px;
            color:#fff;
        }
    }
}

#menu-footer{
    font-size: 15px;
}