body{
    background: url(../images/fond.gif);
    background-repeat: repeat-x;
    background-color: $marron;
    color: $color-text;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
}

.vcenter{
    position: absolute;
    top:50%;
    transform:translateY(-50%);
}

@media (min-width: 768px) {
  .row.equal {
    display: flex;
    flex-wrap: wrap;
  }
}

h1, .h1{
    //text-align:center;
    text-transform: uppercase;
    position:relative;
    padding-bottom:30px;
    color: $color-text;
    font-size: 25px;
    font-style: italic;
}

h2, .h2{
    text-transform: uppercase;
    color: $color-text;
    font-size: 20px;
}
h3, .h3{
    color: $color-text;
    font-size: 18px;
}

h4, .h4{
    color: $marron-clair;
    font-size: 14px;
    font-weight: bold;
}

a {
    color: $marron-clair;
}

a:link{
    color: $marron-clair;
}

a:visited{
    color: $marron-clair;
}

a:hover{
    color: $marron-clair;
}

a:active{
    color: $marron-clair;
}