// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors

$blanc:					#FFFFFF;
$marron-fonce:			#2a0900;
$marron:				#67310b;
$marron-clair:			#f9b700;

$fond-header:			$marron-fonce;
$color-menu:			$marron-clair;
$color-text:			$blanc;

$blue:                  #2A71B8;
$blue-chambray:         #3A539B;
$red:                   #CD191D;
$orange:                #EC7C13;

$green:                 #4EB169;
$green-ming:            #336E7B;

$brand-primary:         $blue;

$headings-color:        $blue;


// Fonts
$font-family-sans-serif:  "Lato", Arial, sans-serif;
$headings-font-family:    "Raleway", sans-serif;;

