.topbar{
    background-color:$fond-header;
    color:$color-text;
    ul{
        margin:0;
        a{
            color:#FFF; display:block; padding:15px;
            transition:all 0.2s ;
        }
    }
    .social{
        font-size:24px;
        a{
            padding:7px;
            &:hover{
                transform:scale(1.1);
            }
        }
    }
}
/**
 * Top menu
 */

 #menu-menu-principal{
    margin-top: 100px;
 }

.navbar-top {
    .nav {

        li > a{
            color:$color-text;
            text-transform: uppercase;
            font-size:12px;
            &:hover{
                background:darken($blue, 10%);
                color:$marron-clair;
            }
        }
    }
}

.navbar-default{
    border: 0px !important;
    background-color:$fond-header;
    margin-bottom:0;
    .nav {
        > li {
            > a{
                color:$color-text;
                //text-transform: uppercase;
                //min-height:70px;
                //padding: 15px 10px;
                &:hover,
                .open>a, 
                .open>a:focus, 
                .open>a:hover
                {
                    background:darken($marron, 10%);
                    color:$marron-clair;
                }
                &.active{
                    background-color:$marron;
                }
            }
            &.active {
                a, 
                a:hover {
                    background-color:darken($marron, 10%);
                    color:$marron-clair;
                }
            }
        }
        .dropdown-menu{
            background-color:$fond-header;
            a{
                color:$marron-clair;
                padding:15px 10px;
            }
        }
        .dropdown-menu>.active>a{
            background-color:darken($marron, 10%);
        }
    }
}

.logo {
    margin:0;
    background-color:#fff;
    img{
        max-width:170px;
        margin:10px 30px 10px 0;
    }
    span{

    }
}

.slogan{
    font-size:26px;
    line-height: 32px;
    font-style:italic;
    color: $blue;

}