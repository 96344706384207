.page-header{
	border-bottom: 0px;
}

.img-avendre{
	border: 1px solid $marron-clair;
}

.img-vendu{
	border: 1px solid $marron-clair;	
}

.soustitre{
	font-size: 15px;
}

.gform_button{
	background-color: $marron-clair;
	font-size: 18px;
	padding: 6px 12px;
	border-radius: 4px;
	border: 1px solid transparent;
	text-align: center;
}

.gfield_required{
	color: white !important;
}